<template>

  <main class="form-signin text-center">
    <form class="form-signin">
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

      <div class="form-floating">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"  v-model="username">
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="password">
        <label for="floatingPassword">Password</label>
      </div>


      <button
          v-if="!loading"
          @click="login"
          class="w-100 btn btn-lg btn-primary"
      >Sign in</button>
      <div v-else class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>

      <div v-if="error.message" class="alert alert-danger">
        {{ error.message }}
      </div>
    </form>
  </main>

</template>
<script>
import { storeSession} from "@/stores/storeSession";
import {storeProduct} from "@/stores/storeProduct";

export default {
    data() {
        return {
            tokenValue: '',
            username: '',
            password: '',
            error: {},
            loading: false
        }
    },
    methods: {
        async login() {
            this.loading = true
            const response = await storeSession.login(this.username, this.password)
            // console.log(response);
            if(response.code && response.code != 200){
                this.error = response
            }else{
                this.error = {}
                // console.log('asd');
                this.forceGetCommonNotes();
                this.forceGetProducts();
                this.forceGetPlants();
            }

            this.loading = false
        },
        async forceGetCommonNotes() {
            this.commonNotes = await storeProduct.getLocalCommonNotes(true)
        },
        async forceGetProducts() {
            this.products = await storeProduct.getLocalProducts(true)
        },
        async forceGetPlants() {
            this.plants = await storeProduct.getLocalPlants(true);
        },
    }
}
</script>
<style scoped>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
