const pzTools = {
  getCookie: (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      let cookieValue =  parts.pop().split(';').shift();
      return pzTools.removeQuotes(cookieValue);
    }

    return false;
  },
  removeQuotes(string){
    return string.replace(/['"]+/g, '');
  },
  generateUuid(){
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
}


export { pzTools }

