<template>
  <div class="home">
    <AppReportList @callForceRerender="forceRerender" :key="reportListKey" />
  </div>
</template>

<script>

import AppReportList from "@/components/AppReportList.vue";

export default {
    name: 'HomeView',
    components: {
        AppReportList
    },
    data() {
        return {
            reportListKey: 0,
        };
    },
    methods: {
        forceRerender() {
            this.reportListKey += 1;
        }
    }

}
</script>
