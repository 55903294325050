<template>

  <div v-if="token">

    <header>
      <!-- Fixed navbar -->
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand" href="/"><img src="/img/logo.png"></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <RouterLink :to="{ name: 'home' }" class="nav-link active">Lista rapportini</RouterLink>
              </li>

            </ul>
            <div class="d-flex">
              <app-user />
              <span class="btn btn-danger"  @click="logout">Logout</span>

            </div>
          </div>
        </div>
      </nav>
    </header>

    <!-- Begin page content -->
    <main class="flex-shrink-0 mt-5">
      <div class="container">
        <router-view />

      </div>
    </main>

    <footer class="footer mt-auto py-3 bg-light">
      <div class="container">
        <span class="text-muted">&copy; Tecnoservice 2024</span>
      </div>
    </footer>
  </div>


  <app-login v-else />

</template>

<script>
import { storeSession} from "@/stores/storeSession";
import AppLogin from "@/components/AppLogin.vue";
import AppUser from "@/components/AppUser.vue";
import {storeUser} from "@/stores/storeUser";

export default {
  components:{
    AppLogin,
    AppUser
  },
  data() {
    return {
      tokenValue: false,
      appParameters: process.env
    }
  },
  computed: {
    token() {
      return storeSession.token;
    },
    tokenIsValid: () => {
      return false
      // return storeSession.tokenIsValid()
    },
    getToken: () => {
      return this.token;
    },
    counter: () => {
      return storeSession.counter
    }
  },
  mounted() {
    // console.log(process.env.VUE_APP_API_URL_AUTH_TOKEN);
    this.tokenValue = localStorage.key('token') ? localStorage.getItem('token') : false;
    storeSession.token = this.tokenValue
  },
  methods: {
    logout() {
        storeSession.logout();
        storeUser.clearUser();
        this.tokenValue = false;
    },
    increment: () => {
      storeSession.increment();
    },
    async login(username, password) {
      await storeSession.login(username, password)
      this.tokenValue = storeSession.token
    }
  }
}

</script>

<style>
main > .container {
  padding: 60px 15px 0;
}
.tl-label{
  position: absolute;
  top:0;
  left: 0;
}
/*.footer {*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  height: 60px;*/
/*  line-height: 60px;*/
/*  background-color: #f5f5f5;*/
/*}*/
</style>
