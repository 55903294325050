<template>

<label class="user">{{ user.email }}</label>
</template>

<script>

import { storeUser } from "@/stores/storeUser";
// import { storeReport } from "@/stores/storeReport";
// import axios from "axios";
// import {storeSession} from "@/stores/storeSession";

export default {
    name: 'AppUser',
    data() {
        return {
            'user': {}
        }
    },
    methods: {
        async getUser() {
            this.user = await storeUser.getUser()
        }
    },
    mounted() {
        this.getUser()
    }
}
</script>
<style scoped>
.user{
  color: #f0f0f0;
  padding: 5px;
  margin: 0 5px;
}
</style>
