import {reactive} from 'vue'
import {storeSession} from "@/stores/storeSession";
import axios from "axios";

export const storeProduct = reactive({
    localStorageProductKey: 'products',
    localStorageCommonNotesKey: 'common_notes',
    localStoragePlantsKey: 'plants',
    products: [],
    commonNotes: [],
    plants: [],
    header: {
        Authorization: 'Bearer ' + storeSession.token
    },
    setProducts(products) {
        this.products = products
        localStorage.setItem(this.localStorageProductKey, JSON.stringify(products))
    },
    clearProducts() {
        this.products = false
        localStorage.removeItem(this.localStorageProductKey)
    },
    setCommonNotes(commonNotes) {
        this.commonNotes = commonNotes
        localStorage.setItem(this.localStorageCommonNotesKey, JSON.stringify(commonNotes))
    },
    clearCommonNotes() {
        this.commonNotes = false
        localStorage.removeItem(this.localStorageCommonNotesKey)
    },
    getProductById(id) {
        const products = this.getProducts()
        return products.find(x => x.id == id)
    },
    getLocalProducts(force = false) {
        // console.log(localStorage.getItem(this.localStorageProductKey))
        if (localStorage.getItem(this.localStorageProductKey) !== null && !force) {
            this.products = JSON.parse(localStorage.getItem(this.localStorageProductKey))
            return this.products
        } else {
            return this.getProducts()
        }
    },
    async getProducts() {
        // if (localStorage.getItem(this.localStorageProductKey) !== null) {
        //     this.products = JSON.parse(localStorage.getItem(this.localStorageProductKey))
        //     return this.products
        // }
        await axios
            .get(process.env.VUE_APP_API_URL_PRODUCTS, {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                }
            })
            .then((response) => {
                this.setProducts(response.data.products)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    this.clearProducts()
                    storeSession.clearToken()
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    this.clearProducts()
                    storeSession.clearToken()
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.clearProducts()
                    storeSession.clearToken()
                }
                // console.log('### storeProducts', error);
            })
        return this.products
    },
    getLocalCommonNotes(force = false) {
        if (localStorage.getItem(this.localStorageCommonNotesKey) !== null && !force) {
            this.commonNotes = JSON.parse(localStorage.getItem(this.localStorageCommonNotesKey))
            return this.commonNotes
        } else {
            return this.getCommonNotes()
        }
    },
    async getCommonNotes() {
        // if (localStorage.getItem(this.localStorageCommonNotesKey) !== null) {
        //     this.commonNotes = JSON.parse(localStorage.getItem(this.localStorageCommonNotesKey))
        //     return this.commonNotes
        // }
        // console.log(process.env.VUE_APP_API_URL_NOTES);
        await axios
            .get(process.env.VUE_APP_API_URL_NOTES, {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                }
            })
            .then((response) => {
                this.setCommonNotes(response.data.commonNotes)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    this.clearCommonNotes()
                    storeSession.clearToken()
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    this.clearCommonNotes()
                    storeSession.clearToken()
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.clearCommonNotes()
                    storeSession.clearToken()
                }
                // console.log('### storeProducts', error);
            })
        return this.commonNotes
    },
    setPlants(plants) {
        this.plants = plants
        localStorage.setItem(this.localStoragePlantsKey, JSON.stringify(plants))
    },
    clearPlants() {
        this.plants = false
        localStorage.removeItem(this.localStoragePlantsKey)
    },
    getLocalPlants(force = false) {
        if (localStorage.getItem(this.localStoragePlantsKey) !== null && !force) {
            this.plants = JSON.parse(localStorage.getItem(this.localStoragePlantsKey))
            return this.plants
        } else {
            return this.getPlants()
        }
    },
    async getPlants() {
        // if (localStorage.getItem(this.localStoragePlantsKey) !== null) {
        //     this.plants = JSON.parse(localStorage.getItem(this.localStoragePlantsKey))
        //     return this.plants
        // }
        // console.log(process.env.VUE_APP_API_URL_PLANTS);
        await axios
            .get(process.env.VUE_APP_API_URL_PLANTS, {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                }
            })
            .then((response) => {
                this.setPlants(response.data.plants)
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    this.clearPlants()
                    storeSession.clearToken()
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    this.clearPlants()
                    storeSession.clearToken()
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.clearPlants()
                    storeSession.clearToken()
                }
            })
        return this.plants
    }
})
