import { createApp } from 'vue'
import VueSignature from "vue-signature-pad"
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


const vueApp = createApp(App)
vueApp.config.globalProperties.$dotToComma = function(amount){
    if (amount !== '' || amount !== undefined || amount !== 0  || amount !== '0' || amount !== null) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return amount;
    }
}
vueApp.use(router)
vueApp.use(VueSignature)


vueApp.mount('#app')
