import { reactive } from 'vue'
import { storeSession } from "@/stores/storeSession";
import axios from "axios";

export const storeUser = reactive({
  localStorageKey: 'user',
  user: null,
  header: {
    "Content-Type": "application/json",
    "Authorization" : "Bearer " + storeSession.token
  },
  setUser(user){
    this.user = user
    localStorage.setItem(this.localStorageKey, JSON.stringify(user))
  },
  clearUser(){
    this.user = false
    localStorage.removeItem(this.localStorageKey)
  },
  async getUser() {
    if(localStorage.getItem(this.localStorageKey) !== null){
      const localUser =  await Promise.resolve(localStorage.getItem(this.localStorageKey))
      this.user = JSON.parse(localUser)
      return this.user
    }
    await axios
      .get(process.env.VUE_APP_API_URL_USER, {
        headers : {
          Authorization: 'Bearer ' + storeSession.token
        }
      })
      .then((response) => {
        this.setUser(response.data.user)
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.clearUser()
          storeSession.clearToken()
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          this.clearUser()
          storeSession.clearToken()
        } else {
          // Something happened in setting up the request that triggered an Error
          this.clearUser()
          storeSession.clearToken()
        }
        console.log('### storeUser',error);
      })

    return this.user

  }
})
