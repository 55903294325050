import { reactive } from 'vue'
import { pzTools} from "@/pzTools";
import jwtDecode from "jwt-decode";

export const storeSession = reactive({
  tokenIsValid: function (){

    let token = localStorage.getItem('token');
    if(token === false || token == null){
      return false;
    }
    let decoded = jwtDecode(token);
    console.log('token ' + parseInt(decoded.exp) * 1000, "now " + Date.now())
    if(parseInt(decoded.exp) * 1000 > Date.now()){
      console.log('logged true')
      return true
    }

    //default
    return false;
  },
  user: null,
  token: false,
  logged: false,
  remoteResponse: {},
  counter : 0,
  increment(){
    this.counter++;
  },
  header: {
    "Content-Type": "application/json",
  },
  async login(username, password) {
    const res = await fetch(process.env.VUE_APP_API_URL_AUTH_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const remoteResponse = await res.json();
    // console.log((remoteResponse))
    if(remoteResponse.token){
      this.token = pzTools.removeQuotes(remoteResponse.token);
      localStorage.setItem('token', this.token)
    }

    return remoteResponse

  },
  logout(){
    localStorage.removeItem('token')
    this.token = false
  },
  clearToken(){
    this.token = false
    localStorage.removeItem('token')
  }

})
